<template>
  <div v-if="isLoaded" class="postOpp-container">
    <div class="senior-post-opp-back">
      <div class="back-top" @click="handleBack">
        <div>
          <ion-icon name="arrow-back-outline" class="big-icon"></ion-icon>
        </div>
        <h2>Back</h2>
      </div>
      <hr />
    </div>

    <form @submit.prevent="handleSubmit" class="form">
      <h4>Career Track</h4>
      <select
        name="careerTrack"
        id="careerTrack"
        v-model="careerTrack"
        required
      >
        <option value="default">Select your career track</option>
        <option value="Art & Design">Art & Design</option>
        <option value="Education & Training">Education & Training</option>
        <option value="Finance">Finance</option>
        <option value="Health & Pharmaceuticals">
          Health & Pharmaceuticals
        </option>
        <option value="Information Technology">Information Technology</option>
        <option value="Law & Government">Law & Government</option>
        <option value="Marketing">Marketing</option>
        <option value="Science & Engineering">Science & Engineering</option>
        <option value="Other">Other</option>
      </select>
      <h4>Company</h4>
      <input type="text" v-model="company" required />
      <h4>Title</h4>
      <input type="text" v-model="title" required />
      <h4>Limit</h4>
      <input type="number" v-model="limit" required />
      <h4>Deadline</h4>
      <input type="date" v-model="deadline" required />
      <h4>Job Description</h4>
      <textarea v-model="JobDes" required />
      <button class="next-step" type="submit">Confirm</button>
    </form>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import getUser from "@/composables/getUser";
import getOpp from "@/composables/Opp/getOpp";
import getProfile from "@/composables/Profile/getProfile";
import editPostOpp from "@/composables/Opp/editPostOpp";

export default {
  props: ["id"],
  async setup(props) {
    const { user } = getUser();
    const router = useRouter();
    const oppData = ref(null);
    const careerTrack = ref("default");
    const company = ref(null);
    const title = ref(null);
    const limit = ref(null);
    const deadline = ref(null);
    const JobDes = ref(null);
    const profile = ref(null);
    const isLoaded = ref(false);

    onMounted(async () => {
      watch(oppData, () => {
        careerTrack.value = oppData.value.category;
        company.value = oppData.value.company;
        title.value = oppData.value.title;
        limit.value = oppData.value.limit;
        deadline.value = oppData.value.deadline.toDate().getFullYear() +
          "-" +
          ("0" + (oppData.value.deadline.toDate().getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + oppData.value.deadline.toDate().getDate()).slice(-2);
        JobDes.value = oppData.value.description;
      })
      await getProfile(profile, user.value.uid);
      await getOpp(oppData, props.id);
      isLoaded.value = true;
    });

    const handleBack = () => {
      router.push({ name: "Senior-PostOppDetail", params: { id: props.id } });
    };

    const handleSubmit = async () => {
      const now = new Date(Date.now());
      const dateArr = deadline.value.split("-");
      const modifyDeadline = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
      if (careerTrack.value == "default") {
        alert("Please choose a Career Track.")
      } else if (modifyDeadline < now) {
        alert("The deadline must be after today.");
      } else if (parseInt(limit.value) <= 0) {
        alert("The limit must be at least 1.");
      } else {
        await editPostOpp(
          props.id,
          {
            category: careerTrack.value,
            company: company.value,
            title: title.value,
            limit: parseInt(limit.value),
            deadline: modifyDeadline,
            description: JobDes.value
          }
        );
        careerTrack.value = "default";
        company.value = null;
        title.value = null;
        limit.value = 0;
        deadline.value = null;
        JobDes.value = null;
        alert("Success: Opportunity details have been changed.")
        router.push({ name: "Senior-PostOppDetail", params: { id: props.id } });
      }
    };

    return {
      isLoaded,
      careerTrack,
      company,
      title,
      limit,
      deadline,
      JobDes,
      handleBack,
      handleSubmit,
    };
  },
};
</script>

<style scoped>
.postOpp-container {
  padding: 3vh 8vh;
  animation: fadein 1s;
}

@media screen and (max-width: 600px) {
  .postOpp-container {
    padding: 3vh 1vh;
  }
}

.senior-post-opp-back {
  width: 100%;
  margin-bottom: 3vh;
}

.form {
  width: 100%;
}

.form h4 {
  width: 100%;
  margin-bottom: 0vh;
}

.form input {
  display: inline;
  border: 2px var(--purple-l) solid;
}

#careerTrack {
  display: block;
  width: 50%;
  font-size: 120%;
  margin: 1vh 0;
  margin-bottom: 2vh;
  padding: 0 1vw;
  outline: none;
  border: 2px var(--purple-l) solid;
  border-radius: 20px;
  height: 35px;
}

.error {
  border-color: red;
}

.form textarea {
  display: inline;
  width: 80%;
  border: 2px var(--purple-l) solid;
}

.next-step {
  display: block;
  margin-top: 2vh;
  border: none;
  background-color: var(--purple-l);
  color: white;
  font-weight: bold;
  font-size: 100%;
  padding: 1vh 0vw;
  width: 10vw;
  min-width: 100px;
  border-radius: 30px;
}

.next-step:hover {
  background-color: var(--purple-l);
  padding: 1vh 0vw;
  cursor: pointer;
}
</style>
