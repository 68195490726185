import { db } from '@/firebase/config'
import firebase from "firebase/app";
import "firebase/firestore";

const editPostOpp = async (oppId, data) => {
  try {
    await db.collection("Opportunities").doc(oppId).update({
      category: data.category,
      company: data.company,
      title: data.title,
      limit: data.limit,
      deadline: new firebase.firestore.Timestamp.fromDate(data.deadline).toDate(),
      description: data.description,
    })
  } catch (error) {
    alert(error)
  }

};

export default editPostOpp
